/* eslint-disable no-unused-vars */
import MinimalLayout from 'layout/MinimalLayout';
import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const Conulting = Loadable(lazy(() => import('views/pages/Conulting')));
const FreeConsulting = Loadable(lazy(() => import('views/pages/FreeConsulting')));
const BookingCal = Loadable(lazy(() => import('views/pages/BookingCal')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const Detailspage = Loadable(lazy(() => import('views/pages/Detailspage.js')));
const PageNotFound = Loadable(lazy(() => import('views/pages/PageNotFound.js')));
const ProviderDialog = Loadable(lazy(() => import('views/pages/ProviderDialog.js')));
const ThankYou = Loadable(lazy(() => import('views/pages/ThankYou')));
// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/pages/login/login3',
            element: <AuthLogin3 />
        },
        {
            path: '/thankyou',
            element: <ThankYou />
        },
        {
            path: '/Conulting',
            element: <Conulting />
        },
        {
            path: '/FreeConsulting',
            element: <FreeConsulting />
        },
        {
            path: '/Detailspage',
            element: <Detailspage />
        },
        {
            path: '/BookingCal',
            element: <BookingCal />
        },
        {
            path: '/provider-profile',
            element: <ProviderDialog />
        },
        {
            path: '*',
            element: <PageNotFound />
        },
        {
            path: '/pages/register/register3',
            element: <AuthRegister3 />
        }
    ]
};

export default AuthenticationRoutes;
